import React from 'react'

const Close = ({onClose}) => {
  return (
    <button type='button' onClick={onClose} className='w-6 h-6 border hover:bg-zinc-200/20 flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
    </button>
  )
}

export default Close