import React from 'react'
import logo from '../../images/logo.png'

const Navbar = ({onSettingOpen}) => {
    return (
        <div className='bg-gray-900'>
            <div className='flex items-center justify-between section h-16 px-4'>
                <img src={logo} alt="" className='h-10' />
                <div className='flex items-center justify-end'>
                    <button onClick={onSettingOpen} className='p-4 hover:text-blue-600 transition-all'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Navbar